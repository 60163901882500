function setEqualHeight() {
    var skelSize = getNumSkelSize();
    $(".grid-news-item article").matchHeight();
    $(".product-grid-product .grid-element-content-header-image").matchHeight();
    $(".product-grid-product .grid-element-title").matchHeight();
    $(".product-grid-product .productshortdesc").matchHeight();
    $(".product-grid-product .grid-element-content").matchHeight();
    $(".grid-news-item .news-image-wrapper").matchHeight();
    $(".grid-news-item .content-header").matchHeight();
    $(".grid-news-item .content-short").matchHeight();
    $(".equal-height").matchHeight();
    /*
    if (skelSize > 3) {
        $(".last-minute-wrapper").matchHeight({target: $("#slider_009_rotator_container-wrapper")});
    }
    */
}
$(window).on("load",function() {
    setEqualHeight();
});
$(window).resize(function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height",maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height","");
            $('#main-menu').css("height","");
        });
    }
});

jQuery(document).ready(function($) {
    responsiveTable(".upcoming-products-table");
});
